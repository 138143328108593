import moment from "moment";
import { io } from "socket.io-client";
import { addNewBooking, saveBookings } from "./store/booking/bookingAction";
import store from "./store";
import icon from "./assets/img/icon.png";
import { toast } from "react-toastify";
const URL =
  process.env.NODE_ENV === "production"
    ? "https://staging-api.classtap.com"
    : "http://localhost:5000";

export const socket = io(URL, {
  autoConnect: false,
});

const basicNotificationOptions = {
  theme: "darkblue",
  vibrate: true,
  silent: false,
  icon: icon,
  badge: icon,
};

export const connectToSocket = (data = {}) => {
  let gymId;
  try {
    gymId = data.gym ? data.gym.id : localStorage.gym_id;

    if (!socket.connected) {
      socket.auth = { sessionId: localStorage.jwtToken, userId: data.id };
      socket.connect();
      playSound(0.0);
    }
  } catch (error) {
    console.log("error connecting to socket", error);
  }
  socket.on("connect", onConnect);
  socket.on("disconnect", onDisconnectToSocket);
  socket.on(`new_booking-${gymId}`, newBookingEvent);
  socket.on(`cancel_booking-${gymId}`, cancelBookingEvent);
};

export const onConnect = () => {
  console.log("socket connected");
};

export const onDisconnectToSocket = () => {
  socket.disconnect();
  console.log("socket disconnected");
};

export const playSound = (volume) => {
  try {
    const audio = new Audio("../assets/voice/notification.mp3");
    audio.volume = volume;
    audio.load();
    try {
      audio.addEventListener(
        "canplaythrough",
        () => {
          console.log("canplaythrough-listener");
          audio.play().catch(() => {
            console.log("catch-listener");
            // window.addEventListener("click", () => {
            //   console.log("click-listener");
            //   audio.play();
            // });
          });
        },
        false
      );
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log("error");
  }
};

const newBookingEvent = (notification) => {
  const { content } = notification;

  toast("New Booking Added!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const newNotif = {
    ...basicNotificationOptions,
    body: `New booking for ${content?.class_name}\n${moment(
      content?.class_date
    ).format("DD-MM-YYYY")} at ${moment(
      content?.class_start_time,
      "HH:mm:ss"
    ).format("hh:mm A")}`,
  };

  if (Notification.permission === "granted") {
    playSound(0.3);
    new Notification("Classtap", newNotif);
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        playSound(0.3);
        new Notification("Classtap", newNotif);
      }
    });
  }

  const { filterCancelBooking } = store.getState().book;

  if (filterCancelBooking === "0") {
    store.dispatch(addNewBooking(content));
  }
};

const cancelBookingEvent = (notification) => {
  const { content } = notification;
  toast(`${content?.class_name} booking cancelled!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const newNotif = {
    ...basicNotificationOptions,
    body: `Booking canceled for ${content?.class_name}\n${moment(
      content?.schedule_date?.date
    ).format("DD-MM-YYYY")} at ${moment(
      content?.schedule_date?.start_time,
      "HH:mm:ss"
    ).format("hh:mm A")}`,
  };
  if (Notification.permission === "granted") {
    playSound(0.2);
    new Notification("Classtap", newNotif);
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        playSound(0.2);
        new Notification("Classtap", newNotif);
      }
    });
  }

  const { filterCancelBooking, bookings } = store.getState().book;

  if (filterCancelBooking === "1") {
    store.dispatch(addNewBooking(content));
  } else {
    const newBookings = bookings.filter((booking) => booking.id !== content.id);
    store.dispatch(saveBookings(newBookings));
  }
};
